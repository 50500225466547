import { useEffect, useState } from "react";

/**
 * Sets up a focus trap for a dialog element when it is shown.
 * @param show - A boolean indicating whether the dialog is shown or not.
 * @param dialog - A React ref object containing the dialog element.
 */
const useFocusTrap = (
  show: Boolean,
  dialog: React.RefObject<HTMLDialogElement>
) => {
  const [focusableElements, setFocusableElements] =
    useState<NodeListOf<Element>>();
  const dialogElement = dialog.current;

  useEffect(() => {
    if (show) {
      const firstElement = focusableElements?.[0];
      const lastElement = focusableElements?.[focusableElements.length - 1];

      const handleTabKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            (lastElement as HTMLElement)?.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            (firstElement as HTMLElement).focus();
          }
        }
      };
      dialogElement?.addEventListener("keydown", handleTabKeyPress);

      return () => {
        dialogElement?.removeEventListener("keydown", handleTabKeyPress);
      };
    }
  }, [dialogElement, focusableElements, show]);

  useEffect(() => {
    /* setter opp en observer som oppdaterer fokuserbare elementer inne i dialogen når de endrer seg */
    if (dialog.current && show) {
      let timer: number; 
      const observer = new MutationObserver(() => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          const focusableElements = dialog.current?.querySelectorAll(
            'button:not([disabled]), [href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
          );
          setFocusableElements(focusableElements);
        }, 0);
      });

      observer.observe(dialog.current, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [dialog, show]);
};

export { useFocusTrap };
