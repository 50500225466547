import Keycloak from "keycloak-js";
import {config} from "../environment.mjs";
import {useAsync} from "../utils";

export enum ResoknadMedlemskap {
  AKTIV = "AKTIV",
  TRUKKET_SEG = "TRUKKET_SEG"
}

type ResoknadStatusEntry = {
  fnr: string
  medlemskap: ResoknadMedlemskap
}

type ResoknadStatusResponse = {
  resokere: Array<ResoknadStatusEntry>
}

export type ResoknadStatus = keyof typeof ResoknadMedlemskap | 'INGEN'

async function getResoknadStatus(keycloak: Keycloak): Promise<ResoknadStatus> {
  await keycloak.updateToken(30);

  const fnr = keycloak?.idTokenParsed?.["preferred_username"] ?? "";

  return fetch(`${config.apiUrl}/api/resoknadstatus`, {
    headers: {Authorization: `Bearer ${keycloak.token}`},
  })
  .then(res => res.json() as Promise<ResoknadStatusResponse>)
  .then(json => json.resokere.find(entry => entry.fnr === fnr)?.medlemskap ?? 'INGEN');
}

export const useResoknadStatus = useAsync(getResoknadStatus)
