import React, {useState} from 'react'
import FilePicker from "../FilePicker"
import LoadingSpinner from "../LoadingSpinner"
import {PktAlert, PktButton, PktIcon} from "@oslokommune/punkt-react"
import {uploadFiles as upload} from "../../api/fileUpload"
import {classes} from "../../utils"
import styles from './styles.module.scss'
import {submitError} from "../../errorReport"
import FileList from "../FileList"
import { useKeycloak } from '../../keycloak'

type FileUploadProps = React.HTMLAttributes<HTMLDivElement> & {
    brukerref: string
    onClose: () => void,
    onCompleted?: (uploadedFiles: Array<File>) => void
}

type UploadState = "select" | "working" | "success" | "error"

const FileUpload = ({onClose, onCompleted, brukerref, ...restProps}: FileUploadProps) => {
    const [uploadState, setUploadState] = useState<UploadState>("select")
    const [files, setFiles] = useState<Array<File>>([])
    const {keycloak} = useKeycloak()

    async function uploadFiles() {
        setUploadState("working")
        try {
            await upload(files, brukerref, keycloak?.idTokenParsed?.["preferred_username"] ?? "")
            setUploadState("success")
        } catch (e: unknown) {
            setUploadState("error")
            console.error("Failed to upload file", e)
            submitError("Failed to upload file", e)
        }
    }

    return (
        <div {...restProps} className={classes(restProps.className, styles.container)}>
            <div className={styles.content}>
                {uploadState === "select" && <>
                    <h1 className="pkt-txt-24-medium">
                        Send inn dokumentasjon
                    </h1>
                    <p className="my-size-8">
                        
                        Her kan du sende inn inntil 5 vedlegg til søknaden din. For eksempel
                        lønnslipper, arbeidskontrakt eller utbetalinger fra NAV.
                        
                    </p>
                    <FilePicker
                        acceptExtensions={['png', 'jpeg', 'jpg', 'doc', 'docx', 'pdf']}
                        maxFileSize={10485760} //10MiB
                        maxNumFiles={5}
                        className="my-size-16"
                        onChangeFiles={setFiles}/>
                    <div className={styles.buttonRow}>
                        <PktButton
                            variant="icon-left"
                            iconName="close"
                            skin="tertiary"
                            className="mr-size-16"
                            onClick={onClose}>
                            Avbryt
                        </PktButton>
                        <PktButton
                            variant="label-only"
                            disabled={files.length === 0}
                            onClick={uploadFiles}>
                            Send inn
                        </PktButton>
                    </div>
                </>
                }
                {uploadState === "working" && <>
                    <div style={{flexGrow: "1", alignItems: "center", display: "flex"}}>
                        <LoadingSpinner/>
                    </div>
                </>}
                {uploadState === "success" && <>
                    <div style={{flexGrow: "1", alignItems: "center", display: "flex", marginLeft: "50px"}}>
                        <div>
                            <h1 className="pkt-txt-24-medium">
                                <PktIcon name="check-big" style={{
                                    marginRight: "0.75rem",
                                    background: "#43F8B6",
                                    borderRadius: "50%",
                                    padding: "0.25rem",
                                    width: "1.65rem",
                                }}/>Filene dine er sendt inn!</h1>
                            <p className="pkt-txt-16-light">Disse filene er nå lagt til søknaden din og sendt til
                                saksbehandler:</p>
                            <FileList files={files} showFileSize={false}
                                      className="pkt-txt-16"
                                      style={{
                                          width: "400px",
                                      }}/>
                        </div>
                    </div>
                    <div className={styles.buttonRow}>
                        <PktButton
                            variant="label-only"
                            onClick={() => {
                                onClose()
                                onCompleted?.(files)
                            }
                            }>
                            Lukk
                        </PktButton>
                    </div>
                </>}
                {uploadState === "error" && <>
                    <PktAlert skin="error" className="mb-size-16">
                        
                        Det er en feil i systemet vårt. Vi jobber med å fikse den. Vennligst prøv på nytt litt senere.
                        
                    </PktAlert>
                    <div className={styles.buttonRow}>
                        <PktButton
                            variant="label-only"
                            onClick={onClose}>
                            
                            Lukk
                            
                        </PktButton>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default FileUpload