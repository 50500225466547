import React from 'react';
import styles from './styles.module.scss'
import {PktIcon} from "@oslokommune/punkt-react";

type InfoProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string,
    text: string,
    iconName: 'calendar' | 'map' | 'district',
}

const Info = ({title, text, iconName}: InfoProps) => {
    return (
        <div className={styles.info}>
            <div className={styles.infoTop}>
                <div className={styles.infoIconWrapper}>
                    <PktIcon name={iconName}/>
                </div>
                <span className={styles.infoTitle}>{title}</span>
            </div>
            <div className={styles.infoBottom}>
                {text}
            </div>
        </div>
    )
}

export default Info

