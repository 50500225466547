import React from 'react'
import LoadingSpinner from "../LoadingSpinner"
import BarnehageBarn from "./Barnehagebarn"
import {
    AlertError,
    AlertNoPlacement,
    AlertIkkeVisBarnehageplass,
} from "../Alert/alerts"
import {useKeycloak} from '../../keycloak'
import {BarnehagebarnErrorType, PlasseringerError, useBarnehagePlasseringer} from "../../api/plasseringer"

const BarnehagebarnList = () => {
    const {keycloak} = useKeycloak()
    const [barn, barnState, error] = useBarnehagePlasseringer(keycloak)


    return (
        <>
            {barnState === 'pending' &&
                <LoadingSpinner/>}
            {barn && barn.length !== 0 && <div
                className="pkt-grid pkt-grid--laptop pkt-grid--center px-size-16 px-size-32--phablet-up mb-size-64"
                style={{}}>
                {barn.map((b, index) =>
                    <BarnehageBarn key={b.navn}
                                   className="pkt-cell pkt-cell--span12 pkt-cell--span6-tablet-big-up mt-size-16--phablet-up"
                                   child={b}
                                   index={index}
                    />)}
            </div>}
            {barn && barn.length === 0 &&
                <AlertNoPlacement/>}
            {error instanceof PlasseringerError && error.errorType === BarnehagebarnErrorType.BarnUkjentPerson &&
                <AlertIkkeVisBarnehageplass/>
            }
            {error instanceof PlasseringerError && error.errorType === BarnehagebarnErrorType.GuardianUkjentPerson &&
                <AlertIkkeVisBarnehageplass/>
            }
            {error instanceof PlasseringerError && error.errorType === BarnehagebarnErrorType.InfoIkkeTilgjengelig &&
                <AlertIkkeVisBarnehageplass/>
            }
            {error instanceof PlasseringerError && error.errorType === BarnehagebarnErrorType.Teknisk &&
                <AlertError/>}
        </>
    )
}


export default BarnehagebarnList
