import React, {useEffect, useState} from "react";
import styles from "./styles.module.scss"
import SoknadStegIcon from "./SoknadStegIcon";
import {classes} from "../../utils";
import {PktButton} from "@oslokommune/punkt-react";

type SoknadStegProps = React.HTMLAttributes<HTMLDivElement> & {
    state: "current" | "past" | "future"
    title: string
    lastStep?: boolean
};
const SoknadSteg = function ({state, title, lastStep, children}: SoknadStegProps) {
    const [showInfo, setShowInfo] = useState(true)

    useEffect(() => {
        setShowInfo(state === 'current')
    }, [state])

    return (
        <div className={styles.stepContainer}>
            <div className={styles.stepIconContainer}>
                <SoknadStegIcon status={state} lastStep={lastStep}/>
            </div>
            <div className={classes(styles.stepContentContainer, state === "current" ? styles.currentStep : '')}>
                <div style={{color: state === 'future' ? "var(--color-grayscale-60)" : "inherit"}}>
                    <strong>{title}</strong>
                </div>
                {state === 'past' &&
                    <PktButton skin="tertiary"
                               variant="icon-right"
                               iconName={showInfo ? "chevron-thin-up" : "chevron-thin-down"}
                               size="small"
                               className={styles.moreInfoButton}
                               onClick={() => setShowInfo(!showInfo)}>
                        {showInfo ? 'Mindre info' : 'Mer info'}
                    </PktButton>}
                {showInfo && children}
            </div>
        </div>
    )
}

export default SoknadSteg
