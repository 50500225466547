import {ThumbFeedback} from "../components/Feedback";
import {v4 as uuidv4} from "uuid";
import Keycloak from "keycloak-js";
import {config} from "../environment.mjs";

type FeedbackProps = {
  system: string,
  location: string, // path to the page where the rating was given
  ref: string, // reference, e.g. to the feature being rated, a feature toggled test id or similar
  rating?: ThumbFeedback,
  comment?: string,
}

const clientId = uuidv4()

export async function postFeedback(keycloak: Keycloak, ratingFeedback: FeedbackProps): Promise<Response> {
  await keycloak.updateToken(30)

  const request = {clientId: clientId, ...ratingFeedback}
  const requestOption = {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(request)
  }

  return fetch(`${config.apiUrl}/api/feedback`, {
    ...requestOption,
  })
}