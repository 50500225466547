import {useState} from "react"
import {useSoknader} from "../../api/soknader"
import {useResoknadStatus} from "../../api/resoknadstatus"
import {trekkHustandOgAlleAktiveSoknader} from "../../api/trekksoknader"
import {useKeycloak} from "../../keycloak"

function useAvsluttSoknad() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)
  const [isSuccess, setIsSuccess] = useState<boolean>()
  const {keycloak} = useKeycloak()
  const [, , , invalidateResoknadStatus] = useResoknadStatus(keycloak)
  const [, , , invalidateSoknader] = useSoknader(keycloak)

  const avsluttSoknader = async () => {
    setLoading(true)
    setError(null)

    try {
      await trekkHustandOgAlleAktiveSoknader(keycloak)
      invalidateResoknadStatus()
      invalidateSoknader()
      setIsSuccess(true)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {loading, error, isSuccess, avsluttSoknader}
}

export default useAvsluttSoknad
