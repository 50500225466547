import React, {useState} from 'react'
import {Soknad} from '../../api/soknader'
import SoknadSteg from "./SoknadSteg"
import Modal from "../Modal/Modal"
import FileUpload from "../FileUpload"
import {PktButton} from "@oslokommune/punkt-react"
import styles from "./styles.module.scss"
import FileList from "../FileList"

type SoknadStatusProps = React.HTMLAttributes<HTMLDivElement> & {
    soknad: Soknad
}

const SoknadStatus = ({soknad, className}: SoknadStatusProps) => {
    const soknadDate = soknad.innsendtdato && new Date(soknad.innsendtdato).toLocaleString("no-NB", {dateStyle: "long"})
    const [showUpload, setShowUpload] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([])

    const FileUploadInfo = () => {
        const files = [...(soknad.brukeropplastetDokumentasjon??[]), ...uploadedFiles]
        return <>
            {soknad.søknadstype === "RESØKNAD" && <p>
                Vi henter informasjon om familiens (husholdningen) inntekt fra i fjor automatisk fra Skatteetaten.
                Har familien fått en vesentlig nedgang i inntekt i år, kan du legge ved dokumentasjon.
            </p>}
            {soknad.søknadstype !== "RESØKNAD" && <p>
                Har du fått beskjed fra oss om å legge til ekstra dokumentasjon, kan du gjøre det her.
            </p>}

            <PktButton
                skin="tertiary"
                size="small"
                iconName="plus-circle"
                onClick={() => setShowUpload(true)}
                variant="icon-left"
                className={styles.uploadButton}>
                    
                    Send inn dokumentasjon
                    
            </PktButton>
            {files.length > 0 && <>
                <p className="mt-size-16 mb-size-4">Disse filene er lagt til søknaden:</p>
                <FileList
                    showFileSize={false}
                    files={files}
                    style={{width: "100%"}}
                    className="pkt-txt-16 mt-size-0"
                />
            </>}
        </>

    }
    return (
        <div className={className}>
            <SoknadSteg title={'Søknaden er mottatt'}
                        state={soknad.søknadsstatus == "VENTER_PÅ_SKATTEOPPGJØR" ? "current" : "past"}>

                {soknad.søknadsstatus === "VENTER_PÅ_SKATTEOPPGJØR" &&
                    <p className="pkt-txt-14">Referansenummer: {soknad.brukerreferanse}</p>
                }
                {soknad.søknadstype !== "RESØKNAD" &&
                    <>
                        <p>
                        
                            Søknad om redusert barnehagepris ble mottatt {soknadDate.toLocaleString()}.
                            Vi henter informasjon om familiens (husholdningen) inntekt automatisk fra Skatteetaten.
                        
                        </p>
                    </>
                }
                {soknad.søknadstype === "RESØKNAD" &&
                    <>
                        <p>
                            
                            Søknad om redusert barnehagepris ble automatisk sendt inn av Oslo
                            kommune {soknadDate.toLocaleString()}.
                            Søknaden gjelder for barnehageåret 2023/2024.
                            
                        </p>
                    </>
                }
                {soknad.søknadsstatus === "VENTER_PÅ_SKATTEOPPGJØR" && soknad.søknadstype === "RESØKNAD" &&
                    <FileUploadInfo/>
                }
                <p>
                    
                    Har du spørsmål, kontakt bydelen på telefon {soknad.bydelTelefonnummer}.
                    
                </p>
            </SoknadSteg>
            <SoknadSteg title={'Søknaden er under behandling'}
                        state={soknad.søknadsstatus == "OVERSENDT_SAKSBEHANDLER" ? "current" : soknad.søknadsstatus === "VENTER_PÅ_SKATTEOPPGJØR" ? "future" : "past"}>

                {soknad.søknadsstatus === "OVERSENDT_SAKSBEHANDLER" &&
                    <p className="pkt-txt-14">Referansenummer: {soknad.brukerreferanse}</p>
                }
                <p>
                    
                    Bydelen behandler nå søknaden din. Svaret på søknaden blir sendt til din digitale postkasse (Altinn,
                    Digipost eller e-Boks).
                    
                </p>
                {soknad.søknadsstatus === "OVERSENDT_SAKSBEHANDLER" &&
                    <FileUploadInfo/>
                }
                <p>
                    
                    Har du spørsmål, kontakt bydelen på telefon {soknad.bydelTelefonnummer}.
                    
                </p>
            </SoknadSteg>
            <SoknadSteg title={'Søknaden er ferdig behandlet'}
                        state={soknad.søknadsstatus === "FERDIGBEHANDLET" ? 'current' : 'future'} lastStep={true}>

                {soknad.søknadsstatus === "FERDIGBEHANDLET" &&
                    <p className="pkt-txt-14">Referansenummer: {soknad.brukerreferanse}</p>
                }
                <p>
                    
                    Du får varsel på e-post eller SMS når svaret er tilgjengelig i din digitale postkasse. Har du
                    reservert deg mot digital post, kommer svaret som brev i posten.
                    
                </p>
                <p>
                    
                    Har du spørsmål om søknaden, kontakt bydelen på telefon {soknad.bydelTelefonnummer}.
                    
                </p>

            </SoknadSteg>
            <Modal show={showUpload}
                   onClose={() => setShowUpload(false)}
                   style={{
                       width: "100%",
                       maxWidth: "601px"
                   }}>
                {/*todo: clean up styles*/}
                <div style={{
                    background: "#fff",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 3px #ccc",
                    padding: "20px",
                    width: "100%",
                }}>
                    <FileUpload
                        brukerref={soknad.brukerreferanse}
                        onCompleted={files => {
                            setUploadedFiles([...uploadedFiles, ...files])
                        }}
                        onClose={() => {
                            setShowUpload(false)
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default SoknadStatus