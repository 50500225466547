import React from "react";
import styles from "./styles.module.scss"
import {PktIcon} from "@oslokommune/punkt-react";

type SoknadStegIconProps = React.HTMLAttributes<HTMLDivElement> & {
    status: "current" | "future" | "past"
    lastStep?: boolean
}
const SoknadStegIcon = function ({status, style, lastStep = false}: SoknadStegIconProps) {
    return (
        <div style={style} className={styles.statusIconContainer}>
            {(status === 'past' || (lastStep && status === 'current')) && <div className={styles.statusIcon}>
                <div className={[styles.statusIconSquare, styles.statusBlue].join(' ')}>
                    <PktIcon name="check-medium" className={styles.statusIconIcon}/>
                </div>
            </div>}
            {(status === 'current' && !lastStep) && <div className={styles.statusIcon}>
                <div className={[styles.statusIconOuter, styles.statusIcon, styles.statusGray].join(' ')}></div>
                <div className={[styles.statusIconInner, styles.statusIcon, styles.statusBlue].join(' ')}></div>
            </div>}
            {status === 'future' && <div className={styles.statusIcon}>
                <div className={[styles.statusGray, styles.statusIconRound].join(' ')}></div>
            </div>}
            {!lastStep && <div className={[styles.statusLine, status === 'past' ? styles.statusBlue : styles.statusGray].join(" ")}/>}
        </div>
    )
}

export default SoknadStegIcon