import Keycloak from "keycloak-js";
import {useAsync} from "../utils";
import {config} from "../environment.mjs";

export type SoknadDokumentasjon = {
  filnavn: string,
  opplastningstidspunkt: string
}

export type Soknad = {
  søknadstype: string, //"RESØKNAD" | "FØRSTEGANG",
  brukerreferanse: string,
  innsendtdato: string,
  søknadsstatus: string, //"OVERSENDT_SAKSBEHANDLER" | "VENTER_PÅ_SKATTEOPPGJØR" | "FERDIGBEHANDLET",
  barnehagenavn: string | null,
  bydel: string,
  bydelTelefonnummer: string,
  hovedsøkerFulltNavn: string,
  brukeropplastetDokumentasjon: Array<SoknadDokumentasjon>
}

type SoknadResponse = {
  søknader: Array<Soknad>
}

async function getSoknader(keycloak: Keycloak): Promise<Array<Soknad>> {
  await keycloak.updateToken(30)
  return fetch(`${config.apiUrl}/api/soknader`, {
    headers: {Authorization: `Bearer ${keycloak.token}`},
  })
  .then(res => res.json() as Promise<SoknadResponse>)
  .then(json =>
    json.søknader.sort((a, b) =>
      a.innsendtdato < b.innsendtdato ? 1 : -1))
}


export const useSoknader = useAsync(getSoknader)