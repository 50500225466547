const limit = 10
let reportCount = 0

type ErrorReport = {
    message: string,
    filename?: string,
    stack?: string
}

export function submitError(message: string, error?: unknown) {
    if (++reportCount > limit) return
    try {
        const report: ErrorReport = {
            message: message ?? ''
        }
        if (error instanceof ErrorEvent) {
            report.message += `: ${error.error?.message ?? error.message}`
            report.filename = error.error?.filename ?? error.filename
            report.stack = error.error?.stack
        } else if (error instanceof Error) {
            report.message += `: ${error.message}`
            report.stack = error.stack
        }
        if (typeof navigator?.sendBeacon === 'function') {
            navigator.sendBeacon('/frontend-error-report', JSON.stringify(report))
        } else {
            fetch('/frontend-error-report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(error)
            })
        }
    } catch (err) {
        console.warn('Failed to report error', err)
    }
}