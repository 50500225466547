import React, {useState} from "react"
import "../main.scss"
import LoadingSpinner from "../components/LoadingSpinner"
import BarnehagebarnList from "../components/BarnehagebarnList"
import SoknadList from "../components/SoknadList"
import {PktTabs} from "@oslokommune/punkt-react"
import {useUsername} from "../utils"
import {useKeycloak} from "../keycloak"
import {AlertResoker} from "../components/Alert/alerts"

function Barnehagepris() {
  const {initialized, keycloak} = useKeycloak()
  const username = useUsername()
  const [visibleContent, setVisibleContent] = useState(0)
  const [tabs, setTabs] = useState([
    {
      text: `Søknadene dine`,
      active: true,
      icon: 'list'
    },
    {
      text: `Barnehagen din`,
      active: false,
      icon: 'house-heart'
    },
  ])

  const content = [
    <SoknadList/>,
    <BarnehagebarnList/>,
  ]

  const changeContent = (id: number) => {
    setTabs(tabs.map((tab, i) => ({...tab, active: i === id})))
    setVisibleContent(id)
  }

  return (
    <>
      <div className="pkt-cell pkt-cell--span12">
        {!initialized && (
          <div className="loading-spinner">
            <LoadingSpinner/>
          </div>
        )}
        <div className="px-size-8 px-size-16--phablet-up">
          <div className="mx-size-8 mx-size-8--tablet-up my-size-24 my-size-64--tablet-up">
            <h2 className="bhg-hello">
              Hei, {username}
            </h2>
            <p className="ingress">
                Her finner du status på søknaden din om redusert barnehagepris.
            </p>
          </div>
          <AlertResoker/>
          <PktTabs tabs={tabs} onTabSelected={changeContent}/>

          <div
            id={`tabpanel-${visibleContent}`}
            role="tabpanel"
            aria-live="polite"
          >
            {content[visibleContent]}
          </div>
        </div>
      </div>
    </>
  )
}

export default Barnehagepris
