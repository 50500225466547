import React from "react"
import {Soknad, useSoknader} from "../../api/soknader"
import {ResoknadStatus, useResoknadStatus } from "../../api/resoknadstatus"
import LoadingSpinner from "../LoadingSpinner"
import styles from "./styles.module.scss"
import SoknadListItem from "./SoknadListItem"
import {classes} from "../../utils"
import {AlertError, EmptyState} from "../Alert/alerts"
import {useKeycloak} from "../../keycloak"


type SoknadListProps = React.HTMLAttributes<HTMLDivElement> & {
  resoknadMedlemskap?: ResoknadStatus
}

const SoknadList = ({style, className = ''}: SoknadListProps) => {
  const {keycloak} = useKeycloak()

  const [resoknadMedlemskap, resoknadMedlemskapStatus] = useResoknadStatus(keycloak)
  const [soknader, soknaderStatus] = useSoknader(keycloak)

  const shouldShowSubtext = resoknadMedlemskapStatus === 'ready'
    && resoknadMedlemskap !== 'INGEN'
    && soknaderStatus === 'ready'
    && soknader?.length === 0

  return (
    <>
      <div className={`${styles.soknadListContainer} ${className}`} style={style}>
        <div className={classes(styles.soknadListHeader, styles.headerText)}>
          <span className={classes(styles.flexColumn)}>Dato</span>
          <span className={classes(styles.flexColumn)}>Søknadstype</span>
          <span className={classes(styles.flexColumn)}>Innsendt av</span>
          <span className={classes(styles.flexColumn, styles.includeOnLargeScreen)}>Status</span>
          <div className={styles.expandButtonColumn} style={{width: "16px"}}></div>
        </div>
        {shouldShowSubtext && (
          <p className={styles.emptyTableSubtext}>
            
              Oversikt over dine søknader vil dukke opp her.
            
          </p>
        )}
        {soknaderStatus === 'ready' && soknader?.length === 0 && resoknadMedlemskap === 'INGEN' && (
          <EmptyState/>
        )}

        {soknaderStatus === 'ready' && soknader?.map((soknad: Soknad, index: number) =>
          <SoknadListItem soknad={soknad}
                          key={soknad.brukerreferanse}
                          expandedByDefault={index === 0}/>)}
      </div>

      {soknaderStatus === 'pending' && <LoadingSpinner/>}

      {soknaderStatus === 'error' && <AlertError/>}
    </>
  )
}

export default SoknadList
