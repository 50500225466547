import React, {useMemo} from "react";
import {PktAlert, PktIcon, PktMessagebox} from "@oslokommune/punkt-react";
import styles from "./alerts.module.scss";
import {classes} from "../../utils";
import {Link} from "react-router-dom";
import {ResoknadMedlemskap, useResoknadStatus} from "../../api/resoknadstatus";
import {useSoknader} from "../../api/soknader";
import {useKeycloak} from "../../keycloak";

export const AlertNoPlacement = () => {
    return (
        <PktAlert
            className={styles.alert}
            skin={"info"}
            title={"Finner ingen barnehageplasser"}
        >
            <p>
                Vi finner ingen barnehageplasser registrert på familien din i Oslo
                kommune. Du må ha en barnehageplass før du kan søke om redusert
                barnehagepris.
            </p>
            <p>
                Har du akkurat takket ja til en barnehageplass, tar det litt tid før den
                dukker opp her.
            </p>
        </PktAlert>
    )
};

export const AlertIkkeVisBarnehageplass = () => {
    return (
        <PktAlert
            className={styles.alert}
            skin={"info"}
            title={"Informasjon om barnehageplassene dine er ikke tilgjengelig her"}
        >
            <p>
                <Link className="pkt-link"
                      to="https://www.oslo.kommune.no/barnehage/kontakt-barnehageadministrasjonen/">
                    Ta kontakt med bydelen din hvis du har spørsmål.
                </Link>
            </p>
        </PktAlert>
    )
};

export const AlertError = () => (
    <PktAlert className={styles.alert} skin={"error"}>
        Det er en feil i systemet vårt. Vi jobber med å fikse den. Vennligst prøv på
        nytt litt senere.
    </PktAlert>
);

const AlertResoknadActiveNoResoknad = () => {
    return (
        <PktMessagebox
            className={`${styles.alert} ${styles.wide} ${styles.messageboxTitle}`}
            title='Vi søker om redusert barnehagepris for deg '
            skin="green"
        >

            <p>
                Du, eller noen i husholdningen din, har tidligere søkt om redusert barnehagepris. Oslo kommune vil
                derfor
                søke på deres vegne hvert år fram til barnet begynner på skolen.
            </p>
            <p>
                Dere trenger ikke gjøre noe selv. Vi sender en bekreftelse når søknad for neste barnehageår er sendt
                til
                behandling.
            </p>

            <p>
                Har dere ikke lenger behov for redusert barnehagepris?{" "}
                <Link className="pkt-link" to="/barnehagepris/avsluttsoknad">
                    Stopp søknadene her.
                </Link>
            </p>
        </PktMessagebox>
    )
}

const AlertResoknadActiveHasResoknad = () => {
    return (
        <PktMessagebox
            className={`${styles.alert} ${styles.wide} ${styles.messageboxTitle}`}
            title='Vi søker om redusert barnehagepris for deg'
            skin="green"
        >
            <p>
                Du, eller noen i husholdningen din, har tidligere søkt om redusert barnehagepris. Oslo kommune har
                derfor
                laget en søknad for dere for neste barnehageår. Denne er nå til behandling.
            </p>
            <p>
                Har dere ikke lenger behov for redusert barnehagepris?{" "}
                <Link className="pkt-link" to="/barnehagepris/avsluttsoknad">
                    Stopp behandling av søknaden her.
                </Link>
            </p>
        </PktMessagebox>
    )
}
const AlertResoknadNotActive = () => {
    return (
        <PktMessagebox
            className={`${styles.alert} ${styles.wide} ${styles.messageboxTitle}`}
            skin={"beige"}
            title='Du har ingen aktive søknader'
        >
            <p>
                Du har tidligere søkt om redusert barnehagepris, men har stoppet alle framtidige søknader. Det betyr
                at
                dere må søke på nytt selv hvis dere får behov for lavere barnehagepris igjen.
            </p>
            <p>
                Har du spørsmål eller mener det har skjedd en feil, ring kontaktsenteret på telefon 21 80 21 80.
            </p>
        </PktMessagebox>
    )
}

export const AlertResoker = () => {
    const {keycloak} = useKeycloak()
    const [soknader, soknaderStatus] = useSoknader(keycloak)
    const [medlemskap, medlemskapStatus] = useResoknadStatus(keycloak)

    const harAktivResoknad = useMemo(() => {
        return soknader?.some(soknad => {
            return soknad.søknadstype === 'RESØKNAD' && (
                soknad.søknadsstatus === 'OVERSENDT_SAKSBEHANDLER' || soknad.søknadsstatus === 'VENTER_PÅ_SKATTEOPPGJØR'
            )
        })
    }, [soknader])

    if (soknaderStatus !== 'ready' || medlemskapStatus !== 'ready') {
        return null
    }

    if (medlemskap === ResoknadMedlemskap.AKTIV && !harAktivResoknad) {
        return <AlertResoknadActiveNoResoknad/>
    } else if (medlemskap === ResoknadMedlemskap.AKTIV && harAktivResoknad) {
        return <AlertResoknadActiveHasResoknad/>;
    } else if (medlemskap === ResoknadMedlemskap.TRUKKET_SEG) {
        return <AlertResoknadNotActive/>;
    } else {
        return null
    }

};

export const EmptyState = () => {
    return (
        <section className={classes(styles.emptyStateMessage, "mt-size-60")}>
            <PktIcon name="alert-information"/>
            <h1 className="pkt-txt-18-medium">
                Finner ingen søknader om redusert barnehagepris
            </h1>

            <p>
                For å få redusert barnehagepris, må du sende inn en søknad. Du kan
                følge behandlingen av søknaden din her.
            </p>

            <a
                className={classes("mt-size-16", "pkt-btn", "pkt-btn--small")}
                href="https://www.oslo.kommune.no/barnehage/pris-og-betaling/"
            >
                Søk om redusert barnehagepris
            </a>
        </section>
    );
};
