import {
  PktButton,
  PktCheckbox,
  PktIcon,
  PktInputWrapper,
} from "@oslokommune/punkt-react"
import styles from "./AvsluttSoknad.module.scss"
import { useNavigate } from "react-router-dom"
import useAvsluttSoknad from "./useAvsluttSoknad"
import Spinner from "../../components/LoadingSpinner"
import { AlertError } from "../../components/Alert/alerts"
import DokumentIkon from "../../assets/xdokument.svg?react"
import { useState } from "react"

const AvsluttSoknad = () => {
  const navigate = useNavigate()
  const { loading, error, isSuccess, avsluttSoknader } = useAvsluttSoknad()

  const [formState, setFormState] = useState({
    isChecked: false,
    hasError: false,
  })

  const onSubmit = () => {
    if (!formState.isChecked) {
      setFormState({ ...formState, hasError: true })
    }

    if (formState.isChecked) {
      avsluttSoknader()
    }
  }

  const onCancel = () => {
    navigate("/barnehagepris")
  }

  if (error) {
    return (
      <div className={`pkt-cell pkt-cell--span12 ${styles.container}`}>
        <AlertError />
        <div className={styles.buttons}>
          <PktButton
            skin="primary"
            variant="icon-left"
            iconName="chevron-thin-left"
            onClick={onCancel}
          >
            Tilbake til oversikten
          </PktButton>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="pkt-cell pkt-cell--span12">
        <Spinner />
      </div>
    )
  }

  if (isSuccess) {
    return (
      <div className={`pkt-cell pkt-cell--span12 ${styles.container}`}>
        <div className={`${styles.icon} ${styles.checkmark}`}>
          <PktIcon name="check-medium" className={styles.checkmark} />
        </div>
          <h1 className="pkt-txt-30" style={{ textAlign: "center" }}>
            Søknadene dine er stoppet
          </h1>
          <p>
            Søknadene dine er stoppet og vi vil ikke lenger søke om redusert barnehagepris for husholdningen din.
          </p>
          <p>
            Får dere behov for redusert barnehagepris igjen, må dere søke på nytt.
          </p>

        <div className={styles.buttons}>
          <PktButton
            skin="primary"
            variant="icon-left"
            iconName="chevron-thin-left"
            onClick={onCancel}
          >
            Tilbake til oversikten
          </PktButton>
        </div>
      </div>
    )
  }

  return (
    <div className={`pkt-cell pkt-cell--span12 ${styles.container}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault()

          onSubmit()
        }}
      >
        <div className={styles.icon}>
          <DokumentIkon />
        </div>
          <h1 className="pkt-txt-30">
            Vil du stoppe søknadene om redusert barnehagepris?
          </h1>
          <p>
            Har dere ikke lenger behov for redusert barnehagepris, kan du velge å stoppe alle framtidige søknader. Det
            betyr at Oslo kommune ikke vil lage flere søknader for husholdningen din i framtiden.  Dette vil ikke
            påvirke det vedtaket dere allerede har om redusert pris.
          </p>
          <p>
            Får dere behov for redusert barnehagepris igjen, må dere søke på nytt.
          </p>
        <PktInputWrapper
          label=""
          forId="avsluttsoknad"
          hasError={formState.hasError}
          errorMessage={`Du må bekrefte at du ønsker å stoppe søknadene for redusert barnehagepris.`}
        >
          <PktCheckbox
            id="avsluttsoknad"
            label={`Jeg bekrefter at jeg ønsker å stoppe søknadene mine for redusert barnehagepris`}
            onChange={(e) =>
              setFormState({ isChecked: e.target.checked, hasError: false })
            }
            hasError={formState.hasError}
          />
        </PktInputWrapper>
        <div className={styles.buttons}>
          <PktButton
            skin="tertiary"
            variant="icon-left"
            iconName="chevron-thin-left"
            onClick={onCancel}
          >
            Avbryt
          </PktButton>
          <PktButton
            skin="primary"
            variant="icon-left"
            iconName="cross-circle-big"
            type="submit"
          >
            Stopp søknader
          </PktButton>
        </div>
      </form>
    </div>
  )
}

export default AvsluttSoknad
