import Keycloak from 'keycloak-js'
import React, { createContext, useContext, useState } from 'react'
import {config} from "./environment.mjs";

const keycloak = new Keycloak({
  url: config.keycloakUrl,
  clientId: config.keycloakClientId,
  realm: config.keycloakRealm,
})

const KeycloakContext = createContext({ keycloak, initialized: false })

export function useKeycloak() {
  return useContext(KeycloakContext)
}

export function KeycloakProvider({ children, LoadingComponent }: { children: React.ReactNode; LoadingComponent: React.ReactNode }) {
  const [initialized, setInitialized] = useState(false)

  let ignore = false
  // init keycloak on mount
  React.useEffect(() => {
    if (ignore) return
    ignore = true
    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        pkceMethod: 'S256',
      })
      .then(() => {
        setInitialized(true)
      })
  }, [])

  if (!initialized) {
    return <>{LoadingComponent}</>
  }

  return <KeycloakContext.Provider value={{ keycloak, initialized }}>{children}</KeycloakContext.Provider>
}

export default keycloak