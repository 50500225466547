// Checks that all env variables are set in a server side environment.
// Used to validate that all env variables are present at build time.
if (typeof window === 'undefined') {
    import.meta.env = new Proxy({}, {
        get(_, p) {
            return process.env[p];
        }
    });
}

export const config = {
    keycloakClientId: import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID,
    keycloakRealm: import.meta.env.VITE_APP_KEYCLOAK_REALM,
    keycloakUrl: import.meta.env.VITE_APP_KEYCLOAK_URL,
    apiUrl: import.meta.env.VITE_APP_API_URL ?? ""
}

// Check that all env variables are set in a client side environment
Object.entries(config).forEach(([k, v]) => {
    if (v=== undefined) {
        throw Error(`Missing env variable: ${k}`)
    }
})




