import React from "react";
import './loading.css'
import { PktLoader } from "@oslokommune/punkt-react";

export default function Index() {
    return (
        <div className="loaderContainer">
           <PktLoader variant="rainbow" size="large" />
        </div>
    )
}