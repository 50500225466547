import React from 'react'
import styles from '../FilePicker/styles.module.scss'
import {classes} from "../../utils"
import {PktIcon} from "@oslokommune/punkt-react"
import {SoknadDokumentasjon} from "../../api/soknader"

type FileListProps = React.HTMLAttributes<HTMLUListElement> & {
    files: Array<File | SoknadDokumentasjon>,
    onRemoveFile?: (file: File | SoknadDokumentasjon) => void,
    showFileSize?: boolean
}

function formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

function fileKey(file: File | SoknadDokumentasjon) {
    if(file instanceof File) {
        return `${file.name}-${file.size}`
    } else {
        return `${file.filnavn}-${file.opplastningstidspunkt}`
    }
}

const FileList = ({files, onRemoveFile, showFileSize = true, ...restProps}: FileListProps) => {

    return (
        <ul {...restProps} className={classes(restProps.className, styles.fileList)}>
            {files.map(file => {
                const name = file instanceof File ? file.name : file.filnavn
                const text = `${name}`
                return (
                    <li key={fileKey(file)} className={classes(styles.fileListItem)}>
                        <PktIcon
                            name="document-text"
                            className={styles.fileListIcon}
                            style={{
                                flexGrow: "0",
                                flexShrink: "0",
                                marginRight: "10px"}}
                        />
                        {/*split the text into two spans, so we get the ellipsis in the middle*/}
                        <span title={name} style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            flexShrink: "1"
                        }}>
                            {text.slice(0, text.length / 2)}
                        </span>
                        <span title={name} style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow:"clip",
                            direction: "rtl", //this is very hacky todo: use canvas.measureText to estimate width of text, then split text proportional to available space instead
                            flexShrink: "1",
                        }}>
                            &#x202A;{text.slice(text.length / 2)}&#x202C;
                        </span>
                        {showFileSize && file instanceof File && <span style={{
                            whiteSpace: "nowrap",
                            flexShrink: "0",
                            flexGrow: "0",
                            marginRight: "10px",
                        }}>
                            &nbsp;({formatBytes(file.size)})
                        </span>}

                        {onRemoveFile && <button className={classes(styles.removeFileButton, "pkt-link-button")} onClick={() => (onRemoveFile?.(file))}>Fjern</button>}
                    </li>
                )})}
        </ul>
    )
}

export default FileList