import React, {useEffect, useRef, useState} from "react";
import styles from './styles.module.scss'
import fadeStyles from './transitions/fade.module.scss'
import slideStyles from './transitions/slide.module.scss'
import {PktAlert, PktButton} from "@oslokommune/punkt-react";
import {classes} from "../../utils";
import {CSSTransition} from 'react-transition-group'
import LoadingSpinner from '../LoadingSpinner'
import {submitError} from "../../errorReport";

type FeedbackState = "thumbs" | "text" | "thanks" | "loading"
export type ThumbFeedback = "THUMBS_UP" | "THUMBS_DOWN"

type FeedbackProps = {
    onThumbsFeedback?: ((thumb: ThumbFeedback) => void | Promise<void>)
    onTextFeedback?: ((text: string) => void | Promise<void>),
    maxTextFeedbackLength?: number
}
export default function Feedback({onThumbsFeedback, onTextFeedback, maxTextFeedbackLength}: FeedbackProps) {
    const [expanded, setExpanded] = useState(false)
    const [state, setState] = useState<FeedbackState>("thumbs")
    const firstElementRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        // Focus on the first element when the feedback dialog is opened
        if (expanded) {
            firstElementRef.current?.focus()
        }
    }, [expanded])

    const CloseButton = () => (
        <PktButton skin="primary"
                   variant="icon-only"
                   iconName="close"
                   className={classes(styles.roundedButton, styles.closeButton)}
                   ref={firstElementRef}
                   onClick={() => {
                       setExpanded(false)
                   }}/>
    )

    const ThumbsScreen = () => {
        async function submitFeedback(feedback: ThumbFeedback) {
            try {
                const promise = onThumbsFeedback?.(feedback)
                if (promise) {
                    setState("loading")
                    await promise
                }
            } catch (err) {
                console.error("Failed to submit feedback", err)
                submitError("Failed to submit feedback", err)
            }
            setState("text")
        }

        return (<>
            <CloseButton/>
            <span
                className={"pkt-txt-18-medium"}
                style={{margin: "32px 0"}}>
                Fant du det du lette etter?
            </span>
            <div style={{marginBottom: "100px"}}>
                <PktButton style={{marginRight: "4px"}}
                           skin="primary"
                           size="large"
                           variant="icon-only"
                           iconName="thumbs-up"
                           onClick={() => submitFeedback("THUMBS_UP")}
                />
                <PktButton skin="primary"
                           size="large"
                           variant="icon-only"
                           iconName="thumbs-down"
                           className={styles.roundedButton}
                           onClick={() => submitFeedback("THUMBS_DOWN")}
                />
            </div>
        </>)
    }

    const TextScreen = () => {
        const textareaRef = useRef<HTMLTextAreaElement>(null)
        const [tooLong, setTooLong] = useState(false)

        async function submitFeedback(feedback?: string) {
            try {
                const promise = onTextFeedback?.(feedback ?? "")
                if (promise) {
                    setState("loading")
                    await promise
                }
            } catch (err) {
                console.error("Failed to submit feedback", err)
                submitError("Failed to submit feedback", err)
            }
            setState("thanks")
        }

        return (
            <>
                <CloseButton/>
                <div className={styles.content}>
                    <div className="pkt-txt-18-medium" style={{marginBottom: "8px"}}>
                        Hva lette du etter?
                    </div>
                    <div className="pkt-txt-16-light" style={{marginBottom: "8px"}}>
                        Ikke skriv personlige opplysninger. Her svarer vi ikke, men trenger du hjelp kan du <a
                        href="https://www.oslo.kommune.no/kontakt/">kontakte oss</a>.
                    </div>
                    <form className="pkt-form">
                        <div className="pkt-form-group">
                        <textarea
                            onInput={ev => {
                                if (maxTextFeedbackLength) {
                                    setTooLong(ev.currentTarget.value.length === maxTextFeedbackLength)
                                }
                            }}
                            maxLength={maxTextFeedbackLength}
                            ref={textareaRef}
                            className="pkt-form-textarea"
                            placeholder={`Skriv her`}>
                        </textarea>
                        </div>
                    </form>
                    {tooLong && <PktAlert skin="error" style={{marginBottom: "10px"}}>
                        Du kan ikke skrive mer enn {maxTextFeedbackLength} tegn
                    </PktAlert>}
                    <div style={{display: "flex", justifyContent: "right", marginRight: "0px", marginBottom: "10px"}}>
                        <PktButton
                            style={{justifySelf: "right"}}
                            skin="primary"
                            variant="label-only"
                            onClick={() => submitFeedback(textareaRef.current?.value)}>
                            Send inn
                        </PktButton>
                    </div>
                </div>
            </>
        )
    }

    const ThanksScreen = () => (
        <>
            <CloseButton/>
            <div className={styles.content} style={{textAlign: "center"}}>
                <div
                    className="pkt-txt-18-medium"
                    style={{marginTop: "32px",}}>
                    Tusen takk!
                </div>
                <div
                    className="pkt-txt-16-light"
                    style={{
                        marginTop: "32px",
                        marginBottom: "76px"
                    }}>
                    Takk for tilbakemeldingen. Det hjelper oss å lage bedre nettsider.
                </div>

            </div>
        </>
    )

    const LoadingScreen = () => (
        <>
            <CloseButton/>
            <div style={{marginBottom: "72px", marginTop: "42px"}}>
                <LoadingSpinner/>
            </div>
        </>)

    const entryButtonRef = useRef(null)
    const dialogRef = useRef(null)

    return (
        <>
            <CSSTransition
                classNames={{...fadeStyles}}
                nodeRef={entryButtonRef}
                in={!expanded}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={300}>
                <div ref={entryButtonRef} className={classes(styles.sticky, styles.entryButtonContainer)}>
                    <PktButton
                        className={classes(styles.entryButton)}
                        size="small"
                        onClick={() => setExpanded(true)}>
                        Fant du det du lette etter?
                    </PktButton>
                </div>
            </CSSTransition>
            <CSSTransition
                classNames={{...slideStyles}}
                nodeRef={dialogRef}
                in={expanded}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={500}>
                <dialog open
                        ref={dialogRef}
                        className={classes(styles.sticky, styles.questionBox)}>
                    {state === 'thumbs' && <ThumbsScreen/>}
                    {state === 'text' && <TextScreen/>}
                    {state === 'thanks' && <ThanksScreen/>}
                    {state === 'loading' && <LoadingScreen/>}
                </dialog>
            </CSSTransition>
        </>
    )
}